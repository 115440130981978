import React from "react";
import Hero from "../../Section/Hero/Hero";
import About from "../../Section/About/About";
import Promo from "../../Section/Promo/Promo";
import Newlaunching from "../../Section/Newlaunching/Newlaunching";
import Viola from "../../Section/Viola/Viola";
import Regia from "../../Section/Regia/Regia";
import Jasmia from "../../Section/Jasmia/Jasmia";
import Gading from "../../Section/Gading/Gading";
import Vanica from "../../Section/Vanica/Vanica";
import Surrounding from "../../Section/Surrounding/Surrounding";
import "./Hero.scss";
import ScrollToAnchor from "./ScrollToAnchor";

const Home = () => {
  return (
    <div className="app">
      <ScrollToAnchor />
      <Hero />
      <About />
      <Promo />
      <Newlaunching />
      <Vanica />
      <Viola />
      <Regia />
      <Jasmia />
      <Gading />
      <Surrounding />
    </div>
  );
};

export default Home;

import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./Pages/Home/Home";
import Navbar from "./Section/Navbar/Navbar";
import Privacy from "./Pages/Privacy/Privacy";
import Footer from "./Section/Footer/Footer";
import { FloatingWhatsApp } from "react-floating-whatsapp";

function App() {
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/privacy-policy" element={<Privacy />} />
      </Routes>
      <a
        rel="noreferrer"
        target="_blank"
        href="https://api.whatsapp.com/send/?phone=6287888100388&text=Halo+Fitri,%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20rumah%20ini%20(More%20Info)%20https://scgcrowngading.com/&type=phone_number&app_absent=0"
      >
        <FloatingWhatsApp />
      </a>
      <Footer />
    </Router>
  );
}

export default App;
